import React from 'react';

export function Section({
  className,
  children,
}: {
  className?: string;
  children?: JSX.Element[] | JSX.Element | string;
}) {
  //Based on Bulma.io's Section
  return (
    <section className={className ? 'section ' + className : 'section'}>
      <div className="container">{children}</div>
    </section>
  );
}

export function ColumnContainer({
  gap = 3,
  centered,
  children,
}: {
  centered?: boolean;
  gap?: number;
  children: JSX.Element | JSX.Element[];
}) {
  //Based on Bulma.io's Columns
  const className =
    'columns' +
    (gap ? ' is-' + gap.toString() : '') +
    (centered ? ' is-centered' : '');
  console.log(className);
  return <div className={className}>{children}</div>;
}

export function Column({
  size,
  offset,
  isNarrowMobile,
  isNarrowTablet,
  isNarrowDesktop,
  children,
}: {
  size?: number;
  offset?: number;
  isNarrowMobile?: boolean;
  isNarrowTablet?: boolean;
  isNarrowDesktop?: boolean;
  children?: JSX.Element | JSX.Element[];
}) {
  const columnClass =
    'column' +
    (size ? ' is-' + size.toString() : '') +
    (offset ? ' is-offset-' + offset.toString() : '') +
    (isNarrowMobile ? ' is-narrow-mobile' : '') +
    (isNarrowTablet ? ' is-narrow-tablet' : '') +
    (isNarrowDesktop ? ' is-narrow-desktop' : '');
  //Based on Bulma.io's Column
  return <div className={columnClass}>{children}</div>;
}

export function ColumnSpacer({ size }: { size: number }) {
  return <div className={'column is-' + size.toString()}></div>;
}
