import React, { useState } from 'react';
import Theme from '../theme';
import MobileOnlyMenu from '../mobile-only-menu';
import Button from '../button/index';

function NavbarMenuItem({
  item,
  scrollRef,
  scrollToId,
  isButton,
}: {
  item: string;
  scrollRef?: string;
  scrollToId?: (id: string) => void;
  isButton?: boolean;
}) {
  const onClickCallback = () => {
    scrollToId && scrollRef && scrollToId(scrollRef);
  };
  return isButton ? (
    <a className="navbar-item">
      <Button
        color="primary"
        size="normal"
        style="rounded"
        onClick={onClickCallback}
      >
        {item}
      </Button>
    </a>
  ) : (
    <a className="navbar-item" onClick={onClickCallback}>
      {item}
    </a>
  );
}

function Navbar({
  logo,
  alt,
  menuItems,
  scrollRefs,
  hasBorder,
  hasShadow,
}: {
  logo: string;
  alt: string;
  menuItems: string[];
  scrollRefs?: string[];
  hasBorder?: boolean;
  hasShadow?: boolean;
}) {
  const logoStyle: React.CSSProperties = {
    marginTop: '10px',
    marginLeft: '10px',
    maxHeight: '50px',
    width: 'auto',
    marginRight: '10px',
  };

  const scrollToId = (id: string) => {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -70;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navBarStyle: React.CSSProperties = {
    width: '100%',
    background: Theme.cincColorPalatte.white,
    boxShadow: hasShadow
      ? '0 1px 6px 0px' + Theme.cincColorPalatte.lightShadow
      : 'none',
    borderBottom: hasBorder
      ? '1px solid #9e9e9e' + Theme.cincColorPalatte.lightBorder
      : 'none',
    top: 0,
    overflow: 'hidden',
    position: 'fixed',
  };

  return (
    <>
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={navBarStyle}
      >
        <div className="navbar-brand">
          <a href="https://cincture.com.na">
            <img src={logo} alt={alt} style={logoStyle} />
          </a>
          <div
            role="button"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            style={{ background: Theme.cincColorPalatte.white }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            {menuItems.map((item, idx) =>
              scrollRefs ? (
                <NavbarMenuItem
                  item={item}
                  scrollRef={scrollRefs[idx]}
                  scrollToId={scrollToId}
                />
              ) : (
                <NavbarMenuItem item={item} />
              )
            )}
            <NavbarMenuItem
              item="Contact Us"
              scrollRef="contact-us"
              scrollToId={scrollToId}
              isButton
            />
          </div>
        </div>
        {showMobileMenu && (
          <MobileOnlyMenu
            menuItems={menuItems}
            scrollRefs={scrollRefs}
            scrollToId={scrollToId}
            setShowMobileMenu={setShowMobileMenu}
          />
        )}
      </nav>
    </>
  );
}

export default Navbar;
