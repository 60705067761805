import React from 'react';
import Theme from '../theme';
import { Heading, HeroBanner, Paragraph } from '../typography/index';
import {
  Section,
  ColumnContainer,
  Column,
  ColumnSpacer,
} from '../layout/index';

function Body() {
  return (
    <>
      <HeroBanner
        title="Welcome to Cincture Asset Management (Pty) Ltd"
        subtitle="“In differentiation, not in uniformity, lies the path of progress” ~ Louis D. Brandeis"
        variant="light"
        hasGradient
        mt={65}
      />
      <Section>
        <Heading size={4} align="centered" id="about-us">
          About Us
        </Heading>
        <ColumnContainer>
          <Column size={4} offset={0}>
            <Heading size={2} align="centered">
              Introduction
            </Heading>
            <Paragraph fontSize={3} align="justified">
              Cincture Asset Management (Pty) Ltd is an 100% Namibian owned,
              independent boutique asset management firm, based in Windhoek. We
              are registered with NAMFISA to operate as a Listed Investment
              Manager since December 2017. Our clients enjoy the qualities of a
              smaller, friendlier asset management firm while benefiting from
              our team’s investment experience. In an industry which is often
              unwelcoming and full of jargon, we aim to be more approachable and
              speak plainly. We feel that clients no longer want the bland,
              overly formal, industry stereotype but prefer a more friendly and
              inclusive firm whose interests are aligned with theirs.
            </Paragraph>
          </Column>
          <Column size={4} offset={0}>
            <Heading size={2} align="centered">
              Our Values
            </Heading>
            <Paragraph fontSize={3} align="left">
              <ul>
                <li>We always place our clients’ interest first</li>
                <li>
                  Provide a professional service with integrity and objectivity
                </li>
                <li>
                  Maintain the ability, skills and knowledge necessary to
                  provide a professional service competently
                </li>
                <li>Passion for investment management</li>
              </ul>
            </Paragraph>
          </Column>
          <Column size={4} offset={0}>
            <Heading size={2} align="centered">
              Meet the team
            </Heading>
            <Paragraph fontSize={3} align="left">
              <ul>
                <li>Abraham Grobler – Managing Director</li>
                <li>Tobias van Zyl – Portfolio Manager</li>
                <li>Cecilia Nekongo – Compliance Manager</li>
              </ul>
            </Paragraph>
          </Column>
        </ColumnContainer>
      </Section>
      <Section className="has-background-white-ter">
        <Heading size={4} align="centered" id="products">
          Products
        </Heading>
        <ColumnContainer centered>
          <ColumnSpacer size={1} />
          <Column>
            <Heading size={2} align="centered">
              Segregated Portfolios
            </Heading>
            <Paragraph fontSize={3} align="justified">
              Segregated portfolios are structured in line with our clients’
              investment goals. What is produced is not “standard” but instead a
              dedicated investment solution. Segregated portfolios have many
              benefits. Contact us for more information.
            </Paragraph>
          </Column>
          <ColumnSpacer size={1} />
          <Column>
            <Heading size={2} align="centered">
              Pooled Portfolios
            </Heading>
            <Paragraph fontSize={3} align="justified">
              Pooled portfolios are used for the smaller investors. It is the
              same principle as Unit Trust Funds but more flexible.
            </Paragraph>
          </Column>
          <ColumnSpacer size={1} />
        </ColumnContainer>
      </Section>
      <Section>
        <Heading size={4} align="centered" id="investment-strategy">
          Investment Strategy
        </Heading>
        <ColumnContainer centered>
          <Column>
            <Heading size={2} align="centered">
              Macro vision
            </Heading>
            <Paragraph fontSize={3} align="justified">
              Macro – We are top-down investors who use global macro-economic
              factors to identify trends that guide our investment decision and
              ultimate portfolio construction.
            </Paragraph>
          </Column>
          <ColumnSpacer size={1} />
          <Column size={3}>
            <Heading size={2} align="centered">
              Quality assurance
            </Heading>
            <Paragraph fontSize={3} align="centered">
              We invest in quality, well researched assets.
            </Paragraph>
          </Column>
          <ColumnSpacer size={1} />
          <Column>
            <Heading size={2} align="centered">
              Smart exposure
            </Heading>
            <Paragraph fontSize={3} align="justified">
              While diversification is a key factor, we would rather own
              concentrated exposures of quality assets than own diluted
              exposures of lower quality assets.
            </Paragraph>
          </Column>
        </ColumnContainer>
      </Section>
      <Section className="has-background-grey-light">
        <Heading size={4} align="centered" id="contact-us">
          The next step: contact us!
        </Heading>
        <Paragraph fontSize={3} align="centered">
          info@cincture.com.na
        </Paragraph>
        <Paragraph fontSize={3} align="centered">
          Tel: +264 811223110
        </Paragraph>
      </Section>
    </>
  );
}

export default Body;
