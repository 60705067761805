import React from 'react';

function Button({
  color,
  size,
  style,
  fullwdith,
  loading,
  disabled,
  fontAwesomeIcon,
  iconSize,
  onClick,
  children,
}: {
  color?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark'
    | 'black'
    | 'text';
  size: 'small' | 'normal' | 'medium' | 'large';
  style?: 'outlined' | 'inverted' | 'rounded';
  fullwdith?: boolean;
  loading?: boolean;
  disabled?: boolean;
  fontAwesomeIcon?: string;
  iconSize?: string;
  onClick: () => void;
  children: string;
}) {
  //Based on Bulma.io's Button
  const buttonClassName =
    'button' +
    (color ? ' is-' + color : '') +
    ' is-' +
    size +
    (style ? ' is-' + style : '') +
    (fullwdith ? ' is-fullwidth' : '') +
    (loading ? ' is-loading' : '') +
    (disabled ? ' is-disabled' : '');

  return (
    <>
      {fontAwesomeIcon ? (
        <button className={buttonClassName} onClick={onClick}>
          <span className={'icon is-' + iconSize}>
            <i className={fontAwesomeIcon} />
          </span>
          <span>{children}</span>
        </button>
      ) : (
        <button className={buttonClassName} onClick={onClick}>
          {children}
        </button>
      )}
    </>
  );
}

export default Button;
