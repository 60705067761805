// eslint-disable-next-line
import logo from './logo.svg';
import './App.css';
import React from 'react';
import Footer from './components/main-app-structure/footer';
import Navbar from './components/main-app-structure/navbar';
import Body from './components/main-app-structure/body';
import cincLogo from './resources/logos/cinc_main.png';

//NOTE: THIS IS THE OLD CINCTURE FE

const menuItems = ['About us', 'Products', 'Investment Strategy'];

const menuScrollRefs = ['about-us', 'products', 'investment-strategy'];

function App() {
  return (
    <>
      <div className="App">
        <Navbar
          logo={cincLogo}
          alt="Cincture logo"
          menuItems={menuItems}
          scrollRefs={menuScrollRefs}
          hasShadow
        />
        <Body />
        <Footer />
      </div>
    </>
  );
}

export default App;
