const Theme = {
  //Localise all our colors and stuff here to find and change them with ease
  mainColorPalatte: {
    eggShell: '#F4F1DE',
    terraCotta: '#E07A5F',
    midnightBlue: '#114B5F',
    emerald: '#1a936f',
    mayaBlue: '#7CC6FE',
  },

  cincColorPalatte: {
    purplePrimary: '#663399',
    purpleDark: '#2A153E',
    white: '#FFFFFF',
    lightShadow: '#dfdfdf',
    lightBorder: ' #9e9e9e',
  },
};

export default Theme;
