import React from 'react';

export function HeroBanner({
  variant,
  title,
  subtitle,
  hasGradient,
  mt,
}: {
  variant: string;
  title: string;
  subtitle: string;
  hasGradient?: boolean;
  mt: number;
}) {
  //Based on Bulma.io's Hero Banner
  //See https://bulma.io/documentation/layout/hero/ for variants
  const bannerVariant = hasGradient
    ? 'hero is-' + variant + ' is-bold'
    : 'hero is-' + variant;

  const bannerStyle: React.CSSProperties = {
    marginTop: mt.toString() + 'px',
    alignContent: 'center',
  };
  return (
    <section className={bannerVariant} style={bannerStyle}>
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-centered">{title}</h1>
          <h2 className="subtitle has-text-centered">{subtitle}</h2>
        </div>
      </div>
    </section>
  );
}

export function Heading({
  size,
  align,
  isSubtitle,
  children,
  id,
}: {
  size: number;
  align: 'left' | 'right' | 'centered' | 'justified';
  isSubtitle?: boolean;
  children: string;
  id?: any;
}) {
  //Based on Bulma.io's Title
  const headingClass = isSubtitle
    ? 'subtitle is-' + (7 - size).toString()
    : 'title is-' + (7 - size).toString() + ' has-text-' + align;

  return (
    <p className={headingClass} id={id}>
      {children}
    </p>
  );
}

export function Paragraph({
  fontSize,
  align,
  weight,
  textTransform,
  children,
}: {
  fontSize: number;
  align: 'left' | 'right' | 'centered' | 'justified';
  weight?: string;
  textTransform?: string;
  children: string | JSX.Element[] | JSX.Element;
}) {
  //See https://bulma.io/documentation/helpers/typography-helpers/ for parameters. Note that I have reversed the order of font sizes.
  const textClass =
    'is-size-' +
    (8 - fontSize).toString() +
    ' has-text-' +
    align +
    (weight ? ' has-text-weight-' + weight : '') +
    (textTransform ? ' is-' + textTransform : '');

  return (
    <div className="content">
      <p className={textClass}>{children}</p>
    </div>
  );
}
