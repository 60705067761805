import React from 'react';
import Theme from '../theme';

function MobileMenuItem({
  item,
  scrollRef,
  setShowMobileMenu,
  scrollToId,
}: {
  item: string;
  scrollRef?: string;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToId?: (id: string) => void;
}) {
  const onClickCallback = () => {
    scrollToId && scrollRef && scrollToId(scrollRef);
    setShowMobileMenu(false);
  };

  return (
    <li>
      <a className="navbar-item has-text-centered" onClick={onClickCallback}>
        {item}
      </a>
    </li>
  );
}

function MobileOnlyMenu({
  menuItems,
  scrollRefs,
  setShowMobileMenu,
  scrollToId,
}: {
  menuItems: string[];
  scrollRefs?: string[];
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToId: (id: string) => void;
}) {
  const menuStyle: React.CSSProperties = {
    width: '100%',
    background: Theme.cincColorPalatte.white,
    boxShadow: '0 3px 6px -6px black',
    position: 'relative',
    zIndex: 1,
  };

  return (
    <aside className="menu" style={menuStyle}>
      <ul className="menu-list">
        {menuItems.map((item, idx) =>
          scrollRefs ? (
            <MobileMenuItem
              item={item}
              scrollRef={scrollRefs[idx]}
              scrollToId={scrollToId}
              setShowMobileMenu={setShowMobileMenu}
            />
          ) : (
            <MobileMenuItem item={item} setShowMobileMenu={setShowMobileMenu} />
          )
        )}
        <MobileMenuItem
          item="Contact Us"
          setShowMobileMenu={setShowMobileMenu}
          scrollRef={'contact-us'}
          scrollToId={scrollToId}
        />
      </ul>
    </aside>
  );
}

export default MobileOnlyMenu;
